<template>
  <div data-class="base-detail-page" class="main-padding">
    <BaseBreadcrumb :items="breadcrumbItems" />
    <div>
      <ValidationObserver ref="base-tab-group">
        <b-card no-body>
          <!-- オーダー取込タブ -->
          <b-tabs card>
            <b-tab
              v-for="(group, index) in inputComponents.slice(0, 1)"
              :key="index"
              :title="group.label"
              class="tab"
            >
              <b-form-row>
                <b-col
                  cols="*"
                  v-for="item in group.inputComponents.slice(0, 1)"
                  :key="item.id"
                >
                  <BaseDetailInputGroup
                    v-if="item.type === 'select'"
                    v-model="formData[item.id]"
                    :columnInfo="item.columnInfo"
                    :options="item.options"
                    :rules="item.rules"
                    :columnType="COLUMN_TYPE.LIST"
                    @change="(obj) => item.onChangeCallback(obj)"
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col
                  cols="6.5"
                  v-for="item in group.inputComponents.slice(1, 2)"
                  :key="item.id"
                >
                  <BaseDateFromTo
                    :labelVisible="true"
                    :fromValue.sync="formData[item.fromId]"
                    :toValue.sync="formData[item.toId]"
                    :fromColumnInfo="item.fromColumnInfo"
                    :toColumnInfo="item.toColumnInfo"
                    :fromRules="item.fromRules"
                    :toRules="item.toRules"
                    :locale="item.locale"
                    :rangeTarget="item.toColumnInfo.domainColumnName"
                    @inputFrom="(obj) => item.onInputCallback1(obj)"
                    @inputTo="(obj) => item.onInputCallback2(obj)"
                  />
                </b-col>
                <b-col
                  cols="3.5"
                  v-for="item in group.inputComponents.slice(2, 3)"
                  :key="item.id"
                >
                  <BaseDetailInputGroup
                    v-if="item.type === 'text'"
                    v-model="formData[item.id]"
                    :columnInfo="item.columnInfo"
                    :rules="item.rules"
                    :maxLength="item.maxLength"
                    :minLength="item.minLength"
                    :columnType="COLUMN_TYPE.TEXT"
                  />
                </b-col>
                <b-col>
                  <span>{{ detailInfo.target_order_unit.label }}</span>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col
                  cols="6.5"
                  v-for="item in group.inputComponents.slice(3, 4)"
                  :key="item.id"
                >
                  <BaseDateFromTo
                    :labelVisible="true"
                    :fromValue.sync="formData[item.fromId]"
                    :toValue.sync="formData[item.toId]"
                    :fromColumnInfo="item.fromColumnInfo"
                    :toColumnInfo="item.toColumnInfo"
                    :fromRules="item.fromRules"
                    :toRules="item.toRules"
                    :locale="item.locale"
                    :rangeTarget="item.toColumnInfo.domainColumnName"
                    @inputFrom="(obj) => item.onInputCallback1(obj)"
                    @inputTo="(obj) => item.onInputCallback2(obj)"
                  />
                </b-col>
                <b-col
                  cols="3.5"
                  v-for="item in group.inputComponents.slice(4, 5)"
                  :key="item.id"
                >
                  <BaseDetailInputGroup
                    v-if="item.type === 'text'"
                    v-model="formData[item.id]"
                    :columnInfo="item.columnInfo"
                    :rules="item.rules"
                    :maxLength="item.maxLength"
                    :minLength="item.maxLength"
                    :columnType="COLUMN_TYPE.TEXT"
                  />
                </b-col>
                <b-col>
                  <span>{{ detailInfo.imported_order_unit.label }}</span>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col md="3" class="ml-md-auto p-3">
                  <BaseButton
                    v-for="(item, index) in buttonItems[0]"
                    :key="index"
                    class="mr-2"
                    :variant="item.variant"
                    :icon="item.icon"
                    :columnInfo="item.columnInfo"
                    :disabled="item.disabled"
                    @click="item.onClickCallback"
                  />
                </b-col>
              </b-form-row>
            </b-tab>
          </b-tabs>
          <!-- オーダー削除タブ -->
          <b-tabs card>
            <b-tab
              v-for="(group, index) in inputComponents.slice(1, 2)"
              :key="index"
              :title="group.label"
              class="tab"
            >
              <b-form-row>
                <b-col
                  cols="6.5"
                  v-for="item in group.inputComponents.slice(0, 1)"
                  :key="item.id"
                >
                  <BaseDateFromTo
                    :labelVisible="true"
                    :fromValue.sync="formData[item.fromId]"
                    :toValue.sync="formData[item.toId]"
                    :fromColumnInfo="item.fromColumnInfo"
                    :toColumnInfo="item.toColumnInfo"
                    :fromRules="item.fromRules"
                    :toRules="item.toRules"
                    :locale="item.locale"
                    :rangeTarget="item.toColumnInfo.domainColumnName"
                    @inputFrom="(obj) => item.onInputCallback1(obj)"
                    @inputTo="(obj) => item.onInputCallback2(obj)"
                  />
                </b-col>
                <b-col
                  cols="3.5"
                  v-for="item in group.inputComponents.slice(1, 2)"
                  :key="item.id"
                >
                  <BaseDetailInputGroup
                    v-if="item.type === 'text'"
                    v-model="formData[item.id]"
                    :columnInfo="item.columnInfo"
                    :rules="item.rules"
                    :maxLength="item.maxLength"
                    :minLength="item.minLength"
                    :columnType="COLUMN_TYPE.TEXT"
                  />
                </b-col>
                <b-col>
                  <span>{{ detailInfo.delete_order_unit.label }}</span>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col md="3" class="ml-md-auto p-3">
                  <BaseButton
                    v-for="(item, index) in buttonItems[1]"
                    :key="index"
                    class="mr-2"
                    :variant="item.variant"
                    :icon="item.icon"
                    :columnInfo="item.columnInfo"
                    :disabled="item.disabled"
                    @click="item.onClickCallback"
                  />
                </b-col>
              </b-form-row>
            </b-tab>
          </b-tabs>
        </b-card>
        <!-- <div md="3" class="ml-md-auto p-3">
          <BaseButton
            v-for="(item, index) in buttonItems[2]"
            :key="index"
            class="mr-2"
            :variant="item.variant"
            :icon="item.icon"
            :columnInfo="item.columnInfo"
            :disabled="item.disabled"
            @click="item.onClickCallback"
          />
        </div> -->
      </ValidationObserver>
    </div>
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
    />
    <!-- 取込モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="executeImport(obj)"
    />
    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="executeDelete(obj)"
    />
  </div>
</template>

<script>
import SimulationDataGenerationInfo from './SimulationDataGenerationInfo'
import { openSubModal } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  executeImport,
  executeDelete,
  goRegister,
  goRemove,
  clear1,
  clear2,
  back,
  dateChange,
} from '@/simulation/simulationDataGeneration/SimulationDataGeneration.js'

const screenId = SCREEN_ID.SIMULATION_DATA_GENERATION

export default {
  name: 'simulationDataGeneration',
  components: {},
  props: {
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      formData: new SimulationDataGenerationInfo(),
      obj: this,
      registerMessage: null,
      removeMessage: null,
      oriData: new SimulationDataGenerationInfo(),
      listOptions: [],
      btnKbn: -1,
    }
  },

  created() {
    init(this, false)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    getListOptions() {
      return this.listOptions
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 取込ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.import,
            disabled: !this.existTargetOrderCount,
            onClickCallback: () => {
              this.btnKbn = 0
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear1,
            onClickCallback: () => {
              clear1(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 削除ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.delete,
            disabled: !this.existDeleteOrderCount,
            onClickCallback: () => {
              this.btnKbn = 1
              goRemove(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear2,
            onClickCallback: () => {
              clear2(this)
            },
          },
        ],
        // ボタングループ#3
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back()
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // オーダー取込情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 0 ブロックコード
              type: 'select',
              id: 'targetBlockCode',
              columnInfo: this.detailInfo.target_block_code,
              options: this.getListOptions,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {
                this.dateChange('block', this)
              },
            },
            {
              // 1 取込元オーダー搬出日From
              //   取込元オーダー搬出日To
              type: 'dateFromTo',
              fromId: 'targetStartDate',
              toId: 'targetEndDate',
              fromColumnInfo: this.detailInfo.target_start_date,
              toColumnInfo: this.detailInfo.target_end_date,
              locale: this.locale,
              //fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
              toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              // onInputCallback1: (...args) => {
              //   console.log(
              //     'onInputCallback1(target)===>from=' +
              //       this.formData.targetStartDate +
              //       ',to=' +
              //       this.formData.targetEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              onInputCallback1: () => {
                this.dateChange('targetFrom', this)
              },
              // onInputCallback2: (...args) => {
              //   console.log(
              //     'onInputCallback2(target)===>from=' +
              //       this.formData.targetStartDate +
              //       ',to=' +
              //       this.formData.targetEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              onInputCallback2: () => {
                this.dateChange('targetTo', this)
              },
            },
            {
              // 2 取込オーダー件数(出力用)
              type: 'text',
              id: 'targetOrderCount',
              columnInfo: this.detailInfo.target_order_count,
              rules: [this.INPUT_TYPE_CHECK.ONLYNUMERIC],
            },
            {
              // 3 取込時オーダー搬出日From
              //   取込時オーダー搬出日To
              type: 'dateFromTo',
              fromId: 'importedStartDate',
              toId: 'importedEndDate',
              fromColumnInfo: this.detailInfo.imported_start_date,
              toColumnInfo: this.detailInfo.imported_end_date,
              locale: this.locale,
              //fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
              toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              // onInputCallback1: (...args) => {
              //   console.log(
              //     'onInputCallback1(imported)===>from=' +
              //       this.formData.importedStartDate +
              //       'to=' +
              //       this.formData.importedEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              //   this.dateChange('importedFrom', this)
              // },
              onInputCallback1: () => {
                this.dateChange('importedFrom', this)
              },
              // onInputCallback2: (...args) => {
              //   console.log(
              //     'onInputCallback2(imported)===>from=' +
              //       this.formData.importedStartDate +
              //       'to=' +
              //       this.formData.importedEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              //   this.dateChange('importedTo', this)
              // },
              onInputCallback2: () => {
                this.dateChange('importedTo', this)
              },
            },
            {
              // 4 登録済みオーダー件数(出力用)
              type: 'text',
              id: 'importedOrderCount',
              columnInfo: this.detailInfo.imported_order_count,
              rules: [this.INPUT_TYPE_CHECK.ONLYNUMERIC],
            },
          ],
        },
        {
          // オーダー削除情報タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 0 取込元オーダー搬出日From
              //   取込元オーダー搬出日To
              type: 'dateFromTo',
              fromId: 'deleteStartDate',
              toId: 'deleteEndDate',
              fromColumnInfo: this.detailInfo.delete_start_date,
              toColumnInfo: this.detailInfo.delete_end_date,
              locale: this.locale,
              //fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
              toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              // onInputCallback1: (...args) => {
              //   console.log(
              //     'onInputCallback1(delete)===>from=' +
              //       this.formData.deleteStartDate +
              //       'to=' +
              //       this.formData.deleteEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              //   this.dateChange('deleteFrom', this)
              // },
              onInputCallback1: () => {
                this.dateChange('deleteFrom', this)
              },
              // onInputCallback2: (...args) => {
              //   console.log(
              //     'onInputCallback2(delete)===>from=' +
              //       this.formData.deleteStartDate +
              //       'to=' +
              //       this.formData.deleteEndDate +
              //       ',value1=' +
              //       args[0]
              //   )
              //   this.dateChange('deleteTo', this)
              // },
              onInputCallback2: () => {
                this.dateChange('deleteTo', this)
              },
            },
            {
              // 2 取込オーダー件数(出力用)
              type: 'text',
              id: 'deleteOrderCount',
              columnInfo: this.detailInfo.delete_order_count,
              rules: [this.INPUT_TYPE_CHECK.ONLYNUMERIC],
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      let label
      if (this.btnKbn == 0) {
        label = this.detailCtrlInfo.import.label
      } else if (this.btnKbn == 1) {
        label = this.detailCtrlInfo.delete.label
      }
      // '%s処理を完了しました。'
      return this.$store.getters['init/getMessage']('YZ00MG995I', label)
    },
    /**
     * 取込対象件数が０か.
     * @return {Boolean}
     */
    existTargetOrderCount() {
      return this.formData.targetOrderCount > 0
    },
    /**
     * 削除対象件数が０か.
     * @return {Boolean}
     */
    existDeleteOrderCount() {
      return this.formData.deleteOrderCount > 0
    },
  },

  methods: {
    openSubModal,
    init,
    executeImport,
    executeDelete,
    goRegister,
    goRemove,
    clear1,
    clear2,
    back,
    dateChange,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}

.group-text {
  width: 150px;
  height: 40px;
}
</style>
