import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}simulation/`

class SimulationDataGenerationService {
  getBlocks() {
    return createAuthenticaionService(`${BASE_URL}blocks/`).get('/')
  }

  getOrderCount(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}orderinfo/`).get(`/?${param}`)
  }

  import(datas) {
    return createAuthenticaionService(`${BASE_URL}import/`).post('/', datas)
  }

  delete(datas) {
    return createAuthenticaionService(`${BASE_URL}delete/`).post('/', datas)
  }
}

export default new SimulationDataGenerationService()
