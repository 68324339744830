import { openMsgModal, openSubModal, formatYYYYMMDD } from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE } from '@/common/const.js'
import router from '@/router'
import SimulationDataGenerationService from './SimulationDataGenerationService'
import _ from 'lodash'
import { isNil, isEmpty } from 'lodash'
/**
 * 初期化処理
 * @param {*} obj
 * @param {*} addBlank
 */
export function init(obj, addBlank = true) {
  obj.$store.dispatch('init/setServiceLoading')
  SimulationDataGenerationService.getBlocks()
    .then(
      (response) => {
        // ブロックコンボボックスリスト作成処理
        obj.listOptions = makeListOptions(response.data, addBlank)

        if (obj.listOptions.length > 0) {
          obj.formData.targetBlockCode = obj.listOptions[0].value
        } else {
          obj.formData.targetBlockCode = null
        }
        // 現在日付
        let currentDate = getNowDate()
        obj.formData.targetStartDate = currentDate
        obj.formData.targetEndDate = currentDate
        obj.formData.importedStartDate = currentDate
        obj.formData.importedEndDate = currentDate
        obj.formData.deleteStartDate = currentDate
        obj.formData.deleteEndDate = currentDate

        obj.formData.targetOrderCount = 0
        obj.formData.importedOrderCount = 0
        obj.formData.deleteOrderCount = 0

        obj.oriData = _.cloneDeep(obj.formData)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 日付変更時、オーダー件数取得処理
 * @param {*} obj
 */
export function getOrderInfo(obj) {
  const validated = obj.$refs['base-tab-group'].validate()
  if (!validated) return

  let newFormData = _.cloneDeep(obj.formData)
  // 日付 YYYY-MM-DD 10桁 → YYYYMMDD 8桁
  convertYYYYMMDD(obj, newFormData)

  obj.$store.dispatch('init/setServiceLoading')
  var callApi
  callApi = SimulationDataGenerationService.getOrderCount(newFormData)
  callApi
    .then(
      (response) => {
        obj.formData = response.data

        // 日付 YYYYMMDD 8桁 → YYYY-MM-DD 10桁
        displayYYYYMMDD(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 取込ボタン押下
 * @param {*} obj
 */
export async function executeImport(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['base-tab-group'].validate()
  if (!validated) return

  let newFormData = _.cloneDeep(obj.formData)
  // 日付 YYYY-MM-DD 10桁 → YYYYMMDD 8桁
  convertYYYYMMDD(obj, newFormData)

  obj.$store.dispatch('init/setServiceLoading')
  var callApi
  callApi = SimulationDataGenerationService.import(newFormData)
  callApi
    .then(
      (response) => {
        obj.formData = response.data

        // 日付 YYYYMMDD 8桁 → YYYY-MM-DD 10桁
        displayYYYYMMDD(obj)

        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')

        // 再表示
        getOrderInfo(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 削除ボタン押下
 * @param {*} obj
 */
export async function executeDelete(obj) {
  obj.$bvModal.hide('remove-modal')

  const validated = await obj.$refs['base-tab-group'].validate()
  if (!validated) return

  let newFormData = _.cloneDeep(obj.formData)
  // 日付 YYYY-MM-DD 10桁 → YYYYMMDD 8桁
  convertYYYYMMDD(obj, newFormData)

  obj.$store.dispatch('init/setServiceLoading')
  var callApi
  callApi = SimulationDataGenerationService.delete(newFormData)
  callApi
    .then(
      (response) => {
        obj.formData = response.data

        // 日付 YYYYMMDD 8桁 → YYYY-MM-DD 10桁
        displayYYYYMMDD(obj)

        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')

        // 再表示
        getOrderInfo(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 取込時、確認メッセージ表示
 * @param {*} obj
 */
export function goRegister(obj) {
  // 件数チェック
  // 取込オーダー件数が0の場合、取込不可
  // 取込時日付の範囲にオーダーが存在する場合：「登録する日付にオーダーが存在しますがよろしいですか？」
  // 取込対象のオーダーが500件を超える場合：「取込対象オーダーが500件を超えています。時間がかかりますがよろしいですか？」YZ00MG983Q
  if (obj.formData.importedOrderCount > 0) {
    obj.registerMessage = obj.$store.getters['init/getMessage']('YZ00MG982Q')
  }
  if (obj.formData.targetOrderCount > 500) {
    obj.registerMessage = obj.$store.getters['init/getMessage']('YZ00MG983Q')
  }
  if (obj.registerMessage == null || obj.registerMessage == '') {
    executeImport(obj)
  } else {
    openSubModal(obj, 'register-modal')
  }
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

/**
 * 日付変換処理
 * @param {*} obj
 */
function convertYYYYMMDD(obj, newFormData) {
  // console.log('before convertYYYYMMDD()==================')
  // console.log('targetStartDate===>' + newFormData.targetStartDate)
  // console.log('targetEndDate===>' + newFormData.targetEndDate)
  // console.log('importedStartDate===>' + newFormData.importedStartDate)
  // console.log('importedEndDate===>' + newFormData.importedEndDate)
  // 日付
  newFormData.targetStartDate = formatYYYYMMDD(obj, newFormData.targetStartDate)
  newFormData.targetEndDate = formatYYYYMMDD(obj, newFormData.targetEndDate)
  newFormData.importedStartDate = formatYYYYMMDD(
    obj,
    newFormData.importedStartDate
  )
  newFormData.importedEndDate = formatYYYYMMDD(obj, newFormData.importedEndDate)
  newFormData.deleteStartDate = formatYYYYMMDD(obj, newFormData.deleteStartDate)
  newFormData.deleteEndDate = formatYYYYMMDD(obj, newFormData.deleteEndDate)
  // console.log('after==================')
  // console.log('targetStartDate===>' + newFormData.targetStartDate)
  // console.log('targetEndDate===>' + newFormData.targetEndDate)
  // console.log('importedStartDate===>' + newFormData.importedStartDate)
  // console.log('importedEndDate===>' + newFormData.importedEndDate)
}

/**
 * カレンダー認識用日付型式で設定
 * @param {*} obj
 */
function displayYYYYMMDD(obj) {
  // console.log('before displayYYYYMMDD()==================')
  // console.log('targetStartDate===>' + obj.formData.targetStartDate)
  // console.log('targetEndDate===>' + obj.formData.targetEndDate)
  // console.log('importedStartDate===>' + obj.formData.importedStartDate)
  // console.log('importedEndDate===>' + obj.formData.importedEndDate)

  // ※日付 'YYYY-MM-DD'でmomentのdiff実行
  obj.formData.targetStartDate = calendarYYYYMMDD(
    obj,
    obj.formData.targetStartDate
  )
  obj.formData.targetEndDate = calendarYYYYMMDD(obj, obj.formData.targetEndDate)
  obj.formData.importedStartDate = calendarYYYYMMDD(
    obj,
    obj.formData.importedStartDate
  )
  obj.formData.importedEndDate = calendarYYYYMMDD(
    obj,
    obj.formData.importedEndDate
  )
  obj.formData.deleteStartDate = calendarYYYYMMDD(
    obj,
    obj.formData.deleteStartDate
  )
  obj.formData.deleteEndDate = calendarYYYYMMDD(obj, obj.formData.deleteEndDate)
  // console.log('after==================')
  // console.log('targetStartDate===>' + obj.formData.targetStartDate)
  // console.log('targetEndDate===>' + obj.formData.targetEndDate)
  // console.log('importedStartDate===>' + obj.formData.importedStartDate)
  // console.log('importedEndDate===>' + obj.formData.importedEndDate)
}

function calendarYYYYMMDD(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return ''
  } else {
    if (!isNil(val) && val.length == 8) {
      var date = obj.$moment(val).format('YYYY-MM-DD')
      return date.toString()
    }
    return val ? val.replace(/\//g, '-') : val
  }
}

/**
 * オーダー取込情報をクリアする
 * @param {*} obj
 */
export function clear1(obj) {
  let original = _.cloneDeep(obj.oriData)
  obj.formData.targetBlockCode = original.targetBlockCode
  obj.formData.targetStartDate = original.targetStartDate
  obj.formData.targetEndDate = original.targetStartDate
  obj.formData.importedStartDate = original.importedStartDate
  obj.formData.importedEndDate = original.importedEndDate
  obj.formData.targetOrderCount = original.targetOrderCount
  obj.formData.importedOrderCount = original.importedOrderCount
}

/**
 * オーダー削除情報をクリアする
 * @param {*} obj
 */
export function clear2(obj) {
  let original = _.cloneDeep(obj.oriData)
  obj.formData.deleteStartDate = original.deleteStartDate
  obj.formData.deleteEndDate = original.deleteEndDate
  obj.formData.deleteOrderCount = original.deleteOrderCount
}

/**
 * 現在日時を取得する
 */
function getNowDate() {
  // '2020-12-16'
  let currentDateWithFormat = new Date().toJSON().slice(0, 10)
  //.replace(/-/g, '/')
  //console.log(currentDateWithFormat)
  return currentDateWithFormat
}

/**
 * コンボボックス用リスト情報を作成する
 * @param {*} datas データリスト
 * @param {*} addBlank ブランク有無
 */
function makeListOptions(datas, addBlank = true) {
  let listOptions = []
  // 先頭に空白を追加
  if (addBlank) {
    listOptions.push({
      ['value']: null,
      ['text']: '',
    })
  }
  if (datas == null) {
    return listOptions
  }
  listOptions = datas
    .map((e) => ({
      ['value']: e.blockCode,
      ['text']: e.blockName,
    }))
    .sort(function (a, b) {
      let valueA = a.value.toUpperCase()
      let valueB = b.value.toUpperCase()
      if (valueA < valueB) {
        return -1
      }
      if (valueA > valueB) {
        return 1
      }
      return 0
    })

  return listOptions
}

/**
 * 戻る
 */
export function back() {
  router.push({
    name: SCREEN_ID.DASHBOARD,
    params: {},
  })
}

/**
 * 日付変更時、オーダー件数取得API呼出
 */
export function dateChange(kbn, obj) {
  //console.log('dateChange===>' + kbn)

  // console.log('before $moment()==================')
  // console.log('targetStartDate===>' + obj.formData.targetStartDate)
  // console.log('targetEndDate===>' + obj.formData.targetEndDate)
  // console.log('importedStartDate===>' + obj.formData.importedStartDate)
  // console.log('importedEndDate===>' + obj.formData.importedEndDate)

  let tStartDate = obj.$moment(new Date(obj.formData.targetStartDate))
  let tEndDate = obj.$moment(new Date(obj.formData.targetEndDate))
  let iStartDate = obj.$moment(new Date(obj.formData.importedStartDate))
  let dStartDate = obj.$moment(new Date(obj.formData.deleteStartDate))
  let dEndDate = obj.$moment(new Date(obj.formData.deleteEndDate))

  let diff = tEndDate.diff(tStartDate, 'days')

  // console.log('after $moment()==================')
  // console.log('tStartDate=>' + tStartDate)
  // console.log('tEndDate=>' + tEndDate)
  // console.log('iStartDate=>' + iStartDate)
  // console.log('dStartDate=>' + dStartDate)
  // console.log('dEndDate=>' + dEndDate)

  // console.log('diff(取込元To-取込元From)=>' + diff)
  if (
    isNaN(diff) ||
    isNaN(iStartDate) ||
    isNaN(dStartDate) ||
    isNaN(dEndDate)
  ) {
    //console.log('diff isNaN=> return')
    // 日付が選択されていない場合、件数を０で再設定
    obj.formData.targetOrderCount
    obj.formData.importedOrderCount
    obj.formData.deleteOrderCount
    return
  }

  // 取込時To設定
  if (diff > 0) {
    const iEndDate = iStartDate.add(diff, 'days').format('YYYY-MM-DD')
    //console.log('再設定 importedEndDate(取込時To)=>' + iEndDate)
    obj.formData.importedEndDate = iEndDate
  } else if (diff <= 0) {
    obj.formData.importedEndDate = obj.formData.importedStartDate
  }

  // オーダー件数取得API呼出
  getOrderInfo(obj)
}
