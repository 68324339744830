/**
 * シミュレーションデータ生成
 */
export default class simulationDataGeneration {
  //取込元ブロック
  targetBlockCode = null
  targetBlockName = null
  //------------------------------
  //取込元オーダー搬出日From
  targetStartDate = null
  //取込元オーダー搬出日To
  targetEndDate = null
  //取込オーダー件数(出力用)
  targetOrderCount = null
  //------------------------------
  //取込時オーダー搬出日From
  importedStartDate = null
  //取込時オーダー搬出日To
  importedEndDate = null
  //登録済みオーダー件数(出力用)
  importedOrderCount = null
  //------------------------------
  //削除対象オーダー搬出日From
  deleteStartDate = null
  //削除対象オーダー搬出日To
  deleteEndDate = null
  //登録済みオーダー件数(出力用)
  deleteOrderCount = null
  //------------------------------

  // deleted = '0'
  // registrationTimeDisplay = null
  // registrationUser = null
  // updateTimeDisplay = null
  // updateUser = null
  // version = null
  // updateByTrigger = null
}
